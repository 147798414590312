import { html } from "lit";

import { RoleMixin } from "../../../shared/pwa-page";
import { AdminPage } from "../../../shared/admin";
import { Session } from "../../../shared/session";

export class BaseHRDashboard extends RoleMixin([Session.ROLE_HR, Session.ROLE_QOGNI_ADMIN], AdminPage) {
  static get properties() {
    return {
      organisationId: { type: String, attribute: 'organisation-id' },
      organisation: { type: Object, attribute: 'organisation' },
    }
  }
}

export class PageDashboardHRGeneral extends BaseHRDashboard {
  render() {
    return html`
      <dashboard-container>
        <section class="card col-3">
          <stat-users
            .filter=${{organisationId: this.organisationId}}
            type="total"
          ></stat-users>
        </section>

        <section class="card col-3">
          <stat-users
            .filter=${{organisationId: this.organisationId}}
            type="activated"
          ></stat-users>
        </section>

        <section class="card col-3">
          <stat-budget
            .filter=${{organisationId: this.organisationId}}
            type="available"
            ?cta=${this.organisationId === app.session.user.organisation_id || app.session.hasRole(Session.ROLE_QOGNI_ADMIN)}
          ></stat-budget>
        </section>

        <section class="card col-3">
          <stat-budget
            .filter=${{organisationId: this.organisationId}}
            type="allocated"
          ></stat-budget>
        </section>

        <!-- -->

        <section class="card col-6">
          <h3>Interest Topics</h3>
          <stat-topics-pie
            .filter=${{organisationId: this.organisationId}}
          ></stat-topics-pie>
        </section>

        <section class="card col-6">
          <h3>Leaderboard (MTD)</h3>
          <stat-leaderboard
            period="mtd"
            .filter=${{organisationId: this.organisationId}}
          ></stat-leaderboard>
        </section>

        <section class="card col-6">
          <stat-fivepillar
            .filter=${{organisationId: this.organisationId}}></stat-fivepillar>
        </section>

        <section class="card col-6">
          <h3>Challenges & Sparks</h3>
          <stat-sparks
            .filter=${{organisationId: this.organisationId}}
          ></stat-sparks>
        </section>
      </dashboard-container>
    `
  }
}
customElements.define('page-dashboard-hr-general', PageDashboardHRGeneral);
