import {RoleMixin} from "../../shared/pwa-page";
import { AdminEditPage, TagField, TranslatableField } from "../../shared/admin";
import {Session} from "../../shared/session";
import {GenericDomain} from "../../domain/generic-domain";

export class PageInterestTopicEdit extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {
  title = 'Interest Topic';

  get fields() {
    return [
      new TranslatableField({
        label: 'Name',
        property: 'name',
        required: true,
        type: String,

        parent: this,
        translatableType: "App\\Models\\InterestTopic",
        translatableId: this.id,
        translatableContext: 'Topic that will be displayed to the user, use a sentence that describes the action the user will take, for example: Moderation in alcohol consumption -> Matigen van alcoholgebruik'
      }),
      {
        label: 'Slug',
        property: 'slug',
        required: false,
        type: String
      }, new TagField({
        object: this.object,
        onChange: (e) => {
          if (! this.object) this.object = {tags: []};
          this.object.tags = e.detail.tags;
          this.requestUpdate();
        },
      }),
    ];
  }

  constructor() {
    super(new GenericDomain('/interest_topics'));
  }
}
