import { html, nothing } from "lit";
import { GenericDomain } from "../../domain/generic-domain";
import { AdminListPage } from "../../shared/admin";
import { RoleMixin } from "../../shared/pwa-page";
import { Session } from "../../shared/session";

const statusMap = {
  pending_upload: {
    name: "Pending Upload",
    color: "yellow",
  },
  pending_scan: {
    name: "Pending Scan",
    color: "yellow",
  },
  scan: {
    name: "Scan",
    color: "yellow",
  },
  pending_mapping: {
    name: "Pending Mapping",
    color: "yellow",
  },
  dry_run: {
    name: "Dry Run",
    color: "blue",
  },
  pending_confirmation: {
    name: "Pending Confirmation",
    color: "yellow",
  },
  importing: {
    name: "Importing",
    color: "green",
  },
  completed: {
    name: "Completed",
    color: "green",
  },
  expired: {
    name: "Expired",
    color: "red",
  },
};

export class PageOrganisationUserImportList extends RoleMixin(
  [Session.ROLE_QOGNI_ADMIN, Session.ROLE_HR],
  AdminListPage
) {
  canDestroy = false;
  searchable = false;

  constructor() {
    super(new GenericDomain("/users/import_tasks"));
  }

  static get properties() {
    return {
      ...super.properties,
      organisationId: {
        type: String,
        attribute: "organisationid",
        routeOrigin: "pathname",
      },
    };
  }

  editUrl(obj) {
    if (obj.status === "completed") return false;

    return `/tenants/organisations/${this.organisationId}/users/import/${obj.id}`;
  }

  newUrl() {
    return `/tenants/organisations/${this.organisationId}/users/import/new`;
  }

  get columns() {
    return [
      { name: "Id", field: "id" },
      { name: 'Created', field: 'created_at', sortable: true, filterable: false, searchable: false, render: (obj) => {
        if (!obj.created_at) return nothing;
        return html`${(new Date(obj.created_at)).format({mode: 'format'})}`;
      }},
      {
        name: "Status",
        field: "status",
        render: (obj, value) => {
          return html`
            <badge-tag class="${statusMap[value].color} filled">
              ${statusMap[value].name}
            </badge-tag>
          `;
        },
      },
    ];
  }
}
