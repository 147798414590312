import {RoleMixin} from "../../shared/pwa-page";
import { AdminEditPage, TagField } from "../../shared/admin";
import {Session} from "../../shared/session";
import {GenericDomain} from "../../domain/generic-domain";

export class PageChannelEdit extends RoleMixin(Session.ROLE_QOGNI_ADMIN, AdminEditPage) {
  title = 'Channel';

  get fields() {
    return [
      {
        label: 'Name',
        property: 'name',
        required: true,
        type: String
      }, {
        label: 'Slug',
        property: 'slug',
        required: false,
        type: String
      }, {
        label: 'Question Categories',
        property: 'question_categories',
        required: false,
        type: 'Select',
        expanded: true,
        multiple: true,
        choices: async () => {
          const domain = new GenericDomain('/question_categories');
          const categories = await domain.list({per_page: 10000});
          return categories.data.map(c => {
            return {
              value: c.id,
              name: c.name
            }
          });
        }
      }, {
        label: 'Description',
        property: 'description',
        required: false,
        type: String,
        expanded: true,
        markdown: true,
        help: 'Leave this empty to be auto generated based on the name',
      }, new TagField({
        object: this.object,
        onChange: (e) => {
          if (! this.object) this.object = {tags: []};
          this.object.tags = e.detail.tags;
          this.requestUpdate();
        },
      }),
    ];
  }

  constructor() {
    super(new GenericDomain('/channels'));
  }
}
