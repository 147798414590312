import { html } from "lit-element";
import { DashboardBlock } from "./base";
import { StatisticsDomain } from "../../../domain/statistics-domain";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";

/**
 * Creates a debounced function that delays invoking the callback
 * until after `wait` milliseconds have elapsed since the last time it was invoked.
 * @param {Function} callback - The function to debounce.
 * @param {number} wait - The number of milliseconds to delay.
 * @returns {Function} A debounced version of the original function.
 */
const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, wait);
  };
};

customElements.define(
  "stat-calculator",
  class CalculatorStat extends DashboardBlock {
    #statDomain;

    constructor() {
      super();
      this.#statDomain = new StatisticsDomain();

      // TODO: fetch from the backend for the organisation
      this.annualBudgetUpperLimit = 34285;
      this.dailyCostUpperLimit = 2000;

      // user input properties
      // TODO: remember and restore
      this.annualBudget = 10000;
      this.dailyCost = 400;

      // properties that are populated from the backend
      this.businessCase = false;
      this.paybackPeriod = false;
      this.roiPrograms = false;
      this.exposedRisk = false;

      this._onDailyCostChange = debounce((e) => {
        this.dailyCost = e.target.value;
        this.fetch(true);
      }, 100);

      this._onAnnualBudgetChange = debounce((e) => {
        this.annualBudget = e.target.value;
        this.fetch(true);
      }, 100);
    }

    static get properties() {
      return {
        ...super.properties,
        annualBudget: { type: Number },
        dailyCost: { type: Number },
        businessCase: { type: Number },
        paybackPeriod: { type: Number },
        roiPrograms: { type: Number },
        exposedRisk: { type: Number },
      };
    }

    async fetch(refresh = false) {
      await super.fetch(refresh);
      if (!this.filter.organisationId) {
        return;
      }
      if (this.data && !refresh) return;

      return Task.run(
        async () => {
          this.data = await this.#statDomain.calculateRoi(
            this.filter.organisationId,
            {
              daily_costs_of_absence: this.dailyCost,
              annual_budget: this.annualBudget,
            }
          );
          this.businessCase = this.data.business_case;
          this.exposedRisk = this.data.exposed_risk;
          this.paybackPeriod = this.data.payback_period;
          this.roiPrograms = this.data.return_on_investment_programs_percentage;
          this.requestUpdate();
        },
        {
          global: false,
          ghost: this,
        }
      );
    }

    render() {
      return html`
        <flex-container>
          <flex-item class="col-6">
            <slider-group>
              <h3>Daily cost for absence</h3>
              <slider-container>
                <label> &euro; 1 </label>
                <range-slider
                  @input=${this._onDailyCostChange}
                  theme="darken"
                  ?disabled=${!this.filter.organisationId}
                  min="1"
                  max="${this.dailyCostUpperLimit}"
                  value="${this.dailyCost}"
                  step=""
                  track-color="#f4f0ea"
                ></range-slider>
                <label>
                  &euro;
                  ${new Intl.NumberFormat().format(this.dailyCostUpperLimit)}
                </label>
              </slider-container>
              <slider-output>
                &euro; ${new Intl.NumberFormat().format(this.dailyCost)}
              </slider-output>
            </slider-group>
          </flex-item>

          <flex-item class="col-6">
            <slider-group>
              <h3>Set annual Qogni Budget</h3>
              <slider-container>
                <label> &euro; 1 </label>
                <range-slider
                  @input=${this._onAnnualBudgetChange}
                  theme="darken"
                  ?disabled=${!this.filter.organisationId}
                  min="1"
                  max="${this.annualBudgetUpperLimit}"
                  value="${this.annualBudget}"
                  step=""
                  track-color="#f4f0ea"
                ></range-slider>
                <label>
                  &euro;
                  ${new Intl.NumberFormat().format(this.annualBudgetUpperLimit)}
                </label>
              </slider-container>
              <slider-output>
                &euro; ${new Intl.NumberFormat().format(this.annualBudget)}
              </slider-output>
            </slider-group>
          </flex-item>
        </flex-container>

        <flex-container>
          <flex-item class="col-3">
            <section class="card output-card red">
              <h5>Exposed Risk (&euro;)</h5>
              <output-value>
                &euro; ${this.exposedRisk !== false ? new Intl.NumberFormat().format(this.exposedRisk) : "--"}
              </output-value>
            </section>
          </flex-item>
          <flex-item class="col-3">
            <section class="card output-card">
              <h5>ROI Programs (%)</h5>
              <output-value>
                ${this.roiPrograms !== false ? new Intl.NumberFormat().format(this.roiPrograms) : "--"}
              </output-value>
            </section>
          </flex-item>
          <flex-item class="col-3">
            <section class="card output-card">
              <h5>Payback Period (days)</h5>
              <output-value>
                ${this.paybackPeriod !== false ? new Intl.NumberFormat().format(this.paybackPeriod) : "--"}
              </output-value>
            </section>
          </flex-item>
          <flex-item class="col-3">
            <section class="card output-card green">
              <h5>Business Case (ROI)</h5>
              <output-value>
                &euro; ${this.businessCase !== false ? new Intl.NumberFormat().format(this.businessCase) : "--"}
              </output-value>
            </section>
          </flex-item>
        </flex-container>
      `;
    }
  }
);
