import { RoleMixin } from "../../shared/pwa-page";
import { AdminListPage, dateTimeRenderer } from "../../shared/admin";
import { Session } from "../../shared/session";
import { GenericDomain } from "../../domain/generic-domain";

customElements.define(
  "page-organisation-user-list",
  class PageOrganisationUserList extends RoleMixin([Session.ROLE_QOGNI_ADMIN, Session.ROLE_HR], AdminListPage) {
    canEdit = true;
    canDestroy = false;
    canAdd = false;
    canView = true;
    searchable = true;
    title = null;

    viewLinkIcon = 'arrow';

    static get properties() {
      return {
        ...super.properties,
        organisationId: { type: String, attribute: 'organisationid', routeOrigin: "pathname" },
        locationId: { type: String, attribute: 'locationid', routeOrigin: "pathname" },
        departmentId: { type: String, attribute: 'departmentid', routeOrigin: "pathname" },
      };
    }

    editUrl(obj) {
      return `/tenants/users/${obj.id}`;
      // return `/tenants/organisations/${this.organisationId}/users/${obj.id}`;
    }

    newUrl() {
      return `/tenants/organisations/${this.organisationId}/users/new`;
    }

    destroyUrl(obj) {
      return `/tenants/organisations/${this.organisationId}/users/${obj.id}/destroy`;
    }

    viewUrl(obj) {
      return `/tenants/users/${obj.id}/view`;
      // return `/tenants/organisations/${this.organisationId}/users/${obj.id}/view`;
    }

    constructor() {
      super(new GenericDomain());
    }

    connectedCallback() {
      if (this.departmentId && this.locationId) {
        this.domain.setBaseUrl(`/organisations/${this.organisationId}/locations/${this.locationId}/departments/${this.departmentId}/users`);
      } else if (this.departmentId) {
        this.domain.setBaseUrl(`/organisations/${this.organisationId}/departments/${this.departmentId}/users`);
      } else if (this.locationId) {
        this.domain.setBaseUrl(`/organisations/${this.organisationId}/locations/${this.locationId}/users`);
      } else {
        this.domain.setBaseUrl(`/organisations/${this.organisationId}/users`);
      }

      super.connectedCallback();
    }

    get columns() {
      return [
        {name: 'Firstname', field: 'firstname', sortable: true, filterable: false, searchable: true},
        {name: 'Lastname', field: 'lastname', sortable: true, filterable: false, searchable: true},
        {name: 'Created', field: 'created_at', sortable: true, render: dateTimeRenderer},
      ];
    }
  }
);
