import { GenericDomain } from "../../domain/generic-domain";
import { AdminEditPage } from "../../shared/admin";
import { RoleMixin } from "../../shared/pwa-page";
import { Session } from "../../shared/session";

export class OptionCategoryEdit extends RoleMixin(
  Session.ROLE_QOGNI_ADMIN,
  AdminEditPage
) {
  title = "Option Category";

  constructor() {
    super(new GenericDomain('/option_categories'));
  }

  get fields() {
    return [
      {
        label: "Name",
        property: "name",
        required: true,
        type: String,
      }, {
        label: 'Question Categories',
        property: 'question_categories',
        required: false,
        type: 'Select',
        expanded: true,
        multiple: true,
        choices: async () => {
          const domain = new GenericDomain('/question_categories');
          const categories = await domain.list({per_page: 10000});
          return categories.data.map(c => {
            return {
              value: c.id,
              name: c.name
            }
          });
        }
      },
    ];
  }
}
