import { html } from "lit";
import { DashboardBlock } from "./base";
import { StatisticsDomain } from "../../../domain/statistics-domain";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { ref } from "lit/directives/ref.js";
import ApexCharts from "apexcharts";

customElements.define(
  "energy-matrix",
  class EnergyMatrixStat extends DashboardBlock {
    #statDomain;

    constructor() {
      super();
      this.#statDomain = new StatisticsDomain();
      this.graph = null;
      this.data = [];
    }

    static get properties() {
      return {
        ...super.properties,
        size: { type: String, attribute: true },
      };
    }

    async fetch(refresh = false) {
      await super.fetch(refresh);
      if (!this.filter.organisationId) {
        this.data = [];
        this.requestUpdate();
        return;
      }
      if (this.data.length > 0 && !refresh) return;

      return Task.run(
        async () => {
          this.data = await this.#statDomain.energyMatrix(
            this.filter.organisationId
          );
          this.requestUpdate();
        },
        {
          global: false,
          ghost: this,
        }
      );
    }

    async getOptions() {
      const { series, labels } = this.data.reduce(
        (acc, cur) => {
          acc.series.push(cur.value);
          acc.labels.push(cur.name);
          return acc;
        },
        { series: [], labels: [] }
      );

      return {
        series: series,
        labels: labels,
        chart: {
          type: "polarArea",
        },
        stroke: {
          colors: ["#fff"],
        },
        fill: {
          opacity: 0.8,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    }

    get value() {
      return null;
    }

    async initGraph(el) {
      if (!this.graph && this.data.length > 0) {
        this.graph = new ApexCharts(el, await this.getOptions());
        this.graph.render();
      }
    }

    render() {
      return html`
        ${this.loading
          ? html`<app-shimmer class="image"></app-shimmer>`
          : this.data.length > 0
          ? html`<div ${ref(this.initGraph.bind(this))}></div>`
          : html`<i>No data available.</i>`}
      `;
    }
  }
);
