import { version as packageVersion } from "../package.json";
import { PageGlossaryDefinitionDestroy } from "./pages/app/glossary-destroy";
import { PageGlossaryDefinitionEdit } from "./pages/app/glossary-edit";
import { PageGlossaryDefinitionList } from "./pages/app/glossary-list";
import { PageApp } from "./pages/app/index";
import { PageInterestTopicEdit } from "./pages/app/interest-topic-edit";
import { PageInterestTopicList } from "./pages/app/interest-topic-list";
import { PageTagEdit } from "./pages/app/tag-edit";
import { PageTagList } from "./pages/app/tag-list";
import { PageArticleCategoryEdit } from "./pages/content/article-category-edit";
import { PageArticleCategoryList } from "./pages/content/article-category-list";
import { PageArticleEdit } from "./pages/content/article-edit";
import { PageArticleList } from "./pages/content/article-list";
import { PageContent } from "./pages/content/index";
import { PageIngredientEdit } from "./pages/content/ingredient-edit";
import { PageIngredientList } from "./pages/content/ingredient-list";
import { OptionCategoryDestroy } from "./pages/content/option-category-destroy";
import { OptionCategoryEdit } from "./pages/content/option-category-edit";
import { OptionCategoryList } from "./pages/content/option-category-list";
import { PageOptionDestroy } from "./pages/content/option-destroy";
import { PageOptionEdit } from "./pages/content/option-edit";
import { PageOptionList } from "./pages/content/option-list";
import { PageRecipeEdit } from "./pages/content/recipe-edit";
import { PageRecipeList } from "./pages/content/recipe-list";
import { PageDashboardAdmin } from "./pages/dashboard/admin";
import { PageDashboardHR } from "./pages/dashboard/hr";
import { PageEnter } from "./pages/enter";
import { PageFeedbackDestroy } from "./pages/feedback/feedback-destroy";
import { PageFeedbackList } from "./pages/feedback/feedback-list";
import { PageFeedbackView } from "./pages/feedback/feedback-view";
import { PageHome } from "./pages/home";
import { PageOrderList } from "./pages/order/order-list";
import { PageOrderView } from "./pages/order/order-view";
import { PageProductEdit } from "./pages/product/product-edit";
import { PageProductList } from "./pages/product/product-list";
import { PageProgramEdit } from "./pages/program/program-edit";
import { PageProgramList } from "./pages/program/program-list";
import { PageQuestionnaire } from "./pages/questionnaire/index";
import { PageQuestionCategoryEdit } from "./pages/questionnaire/question-category-edit";
import { PageQuestionCategoryList } from "./pages/questionnaire/question-category-list";
import { PageQuestionEdit } from "./pages/questionnaire/question-edit";
import { PageQuestionList } from "./pages/questionnaire/question-list";
import { PageQuestionnaireCategoriesList } from "./pages/questionnaire/questionnaire-category-list";
import { PageQuestionnaireDestroy } from "./pages/questionnaire/questionnaire-destroy";
import { PageQuestionnaireView } from "./pages/questionnaire/questionnaire-view";
import { PageResultProfileEditPage } from "./pages/questionnaire/result-profile-edit";
import { PageResultProfileList } from "./pages/questionnaire/result-profile-list";
import { PageResultProfileTextDestroy } from "./pages/questionnaire/result-profile-text-destroy";
import { PageResultProfileTextEdit } from "./pages/questionnaire/result-profile-text-edit";
import { PageChannelAdminDestroy } from "./pages/social/channel-admin-destroy";
import { PageChannelAdminEdit } from "./pages/social/channel-admin-edit";
import { PageChannelAdminList } from "./pages/social/channel-admin-list";
import { PageChannelDestroy } from "./pages/social/channel-destroy";
import { PageChannelEdit } from "./pages/social/channel-edit";
import { PageChannelList } from "./pages/social/channel-list";
import { PageSocial } from "./pages/social/index";
import { PageTenants } from "./pages/tenants/index";
import { PageOrganisationCategoryEdit } from "./pages/tenants/organisation-category-edit";
import { PageOrganisationCategoryList } from "./pages/tenants/organisation-category-list";
import { PageOrganisationDepartmentEdit } from "./pages/tenants/organisation-department-edit";
import { PageOrganisationEdit } from "./pages/tenants/organisation-edit";
import { PageOrganisationLedgerAccountsList } from "./pages/tenants/organisation-ledger-accounts-list";
import { PageOrganisationLedgerTransactionsList } from "./pages/tenants/organisation-ledger-transactions-list";
import { PageOrganisationList } from "./pages/tenants/organisation-list";
import { PageOrganisationLocationDepartmentView } from "./pages/tenants/organisation-location-department-view";
import { PageOrganisationLocationEdit } from "./pages/tenants/organisation-location-edit";
import { PageOrganisationLocationView } from "./pages/tenants/organisation-location-view";
import { PageOrganisationTypeEdit } from "./pages/tenants/organisation-type-edit";
import { PageOrganisationTypeList } from "./pages/tenants/organisation-type-list";
import { PageOrganisationUserImportEdit } from "./pages/tenants/organisation-user-import-edit";
import { PageOrganisationUserImportList } from "./pages/tenants/organisation-user-import-list";
import { PageOrganisationUserInvite } from "./pages/tenants/organisation-user-invite";
import { PageOrganisationView } from "./pages/tenants/organisation-view";
import { PageRoleDestroy } from "./pages/tenants/role-destroy";
import { PageRoleEdit } from "./pages/tenants/role-edit";
import { PageRoleList } from "./pages/tenants/role-list";
import { PageUserDestroy } from "./pages/tenants/user-destroy";
import { PageUserEdit } from "./pages/tenants/user-edit";
import { PageUserList } from "./pages/tenants/user-list";
import { PageUserView } from "./pages/tenants/user-view";
import { Session } from "./shared/session";
import { PageSupplementList } from "./pages/program/supplement-list";
import { PageSupplementEdit } from "./pages/program/supplement-edit";
import { PageProgramManage } from "./pages/program/program-manage";
import { PageLogout } from "./pages/logout";
import { PageOrganisationGroupEdit } from "./pages/tenants/organisation-group-edit";
import { PageOrganisationGroupDestroy } from "./pages/tenants/organisation-group-destroy";

// eslint-disable-next-line no-undef
const env = require("env");
window.env = env;

export const version = env.VERSION ?? packageVersion;

export const config = {
  qoachEmail: "qoach@qogni.com",
  baseUrl: env.API_ROOT,
  parentApiUrl: ["localhost", "127.0.0.1"].includes(location.hostname)
    ? "https://dev.qogni.io/api"
    : env.API_ROOT.replace("/v1.0", ""),
  absoluteUrl: env.PWA_URL,
  environment: env.ENVIRONMENT,
  enableTranslations: true,
  serviceWorker: {
    enabled: !!env.SW_ENABLED,
  },
  tosUrl: "https://qogni.com/legal/",
  privacyPolicyUrl: "https://qogni.com/legal/",

  languages: {
    "da-DK": "Dansk",
    "de-DE": "Deutsch",
    "en-US": "English",
    "es-ES": "Español",
    "fr-FR": "Français",
    "nl-NL": "Nederlands",
    "nb-NO": "Norsk",
    "pt-PT": "Português",
    "sv-SE": "Svenska",
    "tr-TR": "Türkçe",
  },
  defaultLanguage: "en-US",

  routes: {
    "/enter": {
      name: "Enter",
      run: PageEnter,
    },
    "/logout": {
      name: "Logout",
      run: PageLogout,
    },
    "/": {
      name: "Homepage",
      run: PageHome,
      config: {
        icon: "home",
        description: "Your main dashboard",
        menu: {
          active: (activeRoute, r) => {
            return activeRoute?.options?.parentRoute === '/dashboards';
          },
          bottom: {
            primary: true,
            index: 3,
          },
          side: {
            index: 1,
          },
        },
      },
    },
    "/dashboards": {
      routes: {
        "/hr": {
          run: PageDashboardHR
        },
        "/admin": {
          run: PageDashboardAdmin
        }
      }
    },
    "/tenants": {
      run: PageTenants,
      name: "Tenants",
      config: {
        icon: "account",
        menu: {
          bottom: {
            primary: true,
            index: 2,
          },
          side: {
            index: 2,
          },
        },
      },
      routes: {
        "/roles": {
          name: "Roles",
          run: PageRoleList,
          routes: {
            "/:id": {
              name: "Role Edit/Create",
              run: PageRoleEdit,
              routes: {
                "/destroy": {
                  name: "Role Destroy",
                  run: PageRoleDestroy,
                },
              },
            },
          },
        },
        "/users": {
          name: "Users",
          run: PageUserList,
          routes: {
            "/:id": {
              name: "User Edit",
              run: PageUserEdit,
              routes: {
                "/view": {
                  name: "User Details",
                  run: PageUserView,
                },
                "/destroy": {
                  name: "User Destroy",
                  run: PageUserDestroy,
                },
              },
            },
          },
        },
        "/organisations": {
          name: "Organisation",
          run: PageOrganisationList,
          routes: {
            "/:id": {
              name: "Organisation Edit/Create",
              run: PageOrganisationEdit,
              routes: {
                "/view": {
                  name: "Organisation Detail",
                  run: PageOrganisationView,
                },
              },
            },
            "/:organisationId": {
              routes: {
                "/groups/:groupId": {
                  name: "Organisation Group Edit/Create",
                  run: PageOrganisationGroupEdit,
                  routes: {
                    "/destroy": {
                      name: "Organisation Group Destroy",
                      run: PageOrganisationGroupDestroy,
                    }
                  }
                },
                "/locations/:locationId": {
                  name: "Organisation Location Edit/Create",
                  run: PageOrganisationLocationEdit,
                  routes: {
                    "/view": {
                      name: "Organisation Location Detail",
                      run: PageOrganisationLocationView,
                    },
                    "/departments/:departmentId": {
                      name: "Organisation Location Departments Edit/Create",
                      run: PageOrganisationDepartmentEdit,
                      routes: {
                        "/view": {
                          name: "Organisation Location Departments Detail",
                          run: PageOrganisationLocationDepartmentView,
                        }
                      }
                    }
                  }
                },
                "/users": {
                  routes: {
                    "/invite": {
                      name: "Invite Users",
                      run: PageOrganisationUserInvite,
                    },
                    "/import": {
                      routes: {
                        "/list": {
                          name: "Import User(s) List",
                          run: PageOrganisationUserImportList
                        },
                        "/:id": {
                          name: "Import User(s) Edit",
                          run: PageOrganisationUserImportEdit
                        }
                      }
                    }
                  },
                },
                "/ledger": {
                  name: "Organisation Ledger Accounts",
                  run: PageOrganisationLedgerAccountsList,
                  routes: {
                    "/:ledgerAccountId": {
                      name: "Ledger Account Transactions",
                      run: PageOrganisationLedgerTransactionsList
                    }
                  }
                }
              },
            },
          },
        },
        "/organisation_types": {
          name: "Organisation Type",
          run: PageOrganisationTypeList,
          routes: {
            "/:id": {
              name: "Organisation Type Edit/Create",
              run: PageOrganisationTypeEdit,
            },
          },
        },
        "/organisation_categories": {
          name: "Organisation Category",
          run: PageOrganisationCategoryList,
          routes: {
            "/:id": {
              name: "Organisation Category Edit/Create",
              run: PageOrganisationCategoryEdit,
            },
          },
        },
      },
    },
    "/content": {
      name: "Content",
      run: PageContent,
      config: {
        icon: "document",
        role: Session.ROLE_QOGNI_ADMIN,
        menu: {
          bottom: {
            index: 4,
          },
          side: {
            index: 3,
          },
        },
      },
      routes: {
        "/recipes": {
          name: "Recipe List",
          run: PageRecipeList,
          routes: {
            "/:id": {
              name: "Recipe Edit",
              run: PageRecipeEdit,
            },
          },
        },
        "/ingredients": {
          name: "Ingredient List",
          run: PageIngredientList,
          routes: {
            "/:id": {
              name: "Ingredient Edit",
              run: PageIngredientEdit,
            },
          },
        },
        "/article_categories": {
          name: "Article Category List",
          run: PageArticleCategoryList,
          routes: {
            "/:id": {
              name: "Article Category Edit",
              run: PageArticleCategoryEdit,
            },
          },
        },
        "/articles": {
          name: "Article List",
          run: PageArticleList,
          routes: {
            "/:id": {
              name: "Article Edit",
              run: PageArticleEdit,
            },
          },
        },
        "/options": {
          name: "Option List",
          run: PageOptionList,
          routes: {
            "/:id": {
              name: "Option Edit",
              run: PageOptionEdit,
              routes: {
                "/destroy": {
                  name: "Option Destroy",
                  run: PageOptionDestroy,
                },
              },
            },
          },
        },
        "/option-categories": {
          name: "Option Category List",
          run: OptionCategoryList,
          routes: {
            "/:id": {
              name: "Option Category Edit",
              run: OptionCategoryEdit,
              routes: {
                "/destroy": {
                  name: "Option Category Destroy",
                  run: OptionCategoryDestroy,
                },
              },
            },
          },
        },
      },
    },
    "/programs": {
      name: "Programs",
      run: PageProgramList,
      config: {
        icon: "blocks",
        role: Session.ROLE_QOGNI_ADMIN,
        menu: {
          bottom: {
            index: 5,
          },
          side: {
            index: 4,
          },
        },
      },
      routes: {
        "/supplements": {
          name: "Supplement List",
          run: PageSupplementList,
          routes: {
            "/:id": {
              name: "Supplement Edit",
              run: PageSupplementEdit,
            },
          }
        },
        "/:id": {
          name: "Program Edit",
          run: PageProgramEdit,
          routes: {
            "/manage": {
              name: "Program Management",
              run: PageProgramManage,
              routes: {
                "/days/:selectedday": {
                  name: "Program Management",
                  run: PageProgramManage
                }
              }
            }
          }
        },
      },
    },
    "/social": {
      name: "Social",
      run: PageSocial,
      config: {
        icon: "people",
        role: Session.ROLE_QOGNI_ADMIN,
        menu: {
          side: {
            index: 5,
          },
        },
      },
      routes: {
        "/channels": {
          name: "Channel List",
          run: PageChannelList,
          routes: {
            "/:id": {
              name: "Channel Edit",
              run: PageChannelEdit,
              routes: {
                "/destroy": {
                  name: "Channel Destroy",
                  run: PageChannelDestroy,
                },
              },
            },
            "/:channelId": {
              routes: {
                "/admins": {
                  name: "Channel Admin List",
                  tagName: "page-channel-admin-list",
                  run: PageChannelAdminList,
                  routes: {
                    "/:id": {
                      name: "Channel Admin Edit",
                      run: PageChannelAdminEdit,
                      routes: {
                        "/destroy": {
                          name: "Channel Admin Destroy",
                          run: PageChannelAdminDestroy,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    "/questionnaires": {
      name: "Questionnaires",
      run: PageQuestionnaire,
      config: {
        icon: "brain",
        role: Session.ROLE_QOGNI_ADMIN,
        menu: {
          side: {
            index: 6,
          },
        },
      },
      routes: {
        "/categories": {
          name: "Questionnaire Categories List",
          run: PageQuestionnaireCategoriesList,
        },
        "/questions": {
          name: "Question List",
          run: PageQuestionList,
          routes: {
            "/:id": {
              name: "Question Edit",
              run: PageQuestionEdit,
            },
          },
        },
        "/question_categories": {
          name: "Question Category List",
          run: PageQuestionCategoryList,
          routes: {
            "/:id": {
              name: "Question Category Edit",
              run: PageQuestionCategoryEdit,
            },
          },
        },
        "/result_profiles": {
          name: "Result Profiles",
          run: PageResultProfileList,
          routes: {
            "/:resultProfileId": {
              name: "Result Profile Edit",
              run: PageResultProfileEditPage,
              routes: {
                "/texts": {
                  routes: {
                    "/:id": {
                      name: "Result Profile Text Edit",
                      run: PageResultProfileTextEdit,
                      routes: {
                        "/destroy": {
                          name: "Result Profile Text Destroy",
                          run: PageResultProfileTextDestroy,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        "/:id": {
          routes: {
            "/view": {
              name: "Questionnaire Details",
              run: PageQuestionnaireView,
            },
            "/destroy": {
              name: "Questionnaire Destroy",
              run: PageQuestionnaireDestroy,
            },
          },
        },
      },
    },
    "/products": {
      name: "Products",
      run: PageProductList,
      config: {
        icon: "idea",
        role: Session.ROLE_QOGNI_ADMIN,
        menu: {
          side: {
            index: 7,
          },
        },
      },
      routes: {
        "/:id": {
          name: "Product Edit",
          run: PageProductEdit,
        },
      },
    },
    "/orders": {
      name: "Orders",
      run: PageOrderList,
      config: {
        icon: "metrics",
        role: Session.ROLE_QOGNI_ADMIN,
        menu: {
          side: {
            index: 8,
          },
        },
      },
      routes: {
        "/:id": {
          name: "Orders",
          run: PageOrderView,
        },
      },
    },
    "/feedback": {
      name: "Feedback",
      run: PageFeedbackList,
      config: {
        icon: "horn",
        role: Session.ROLE_QOGNI_ADMIN,
        menu: {
          side: {
            index: 9,
          },
        },
      },
      routes: {
        "/:id": {
          run: PageFeedbackView,
          routes: {
            "/destroy": {
              run: PageFeedbackDestroy,
            },
          },
        },
      },
    },
    "/app": {
      name: "App",
      run: PageApp,
      config: {
        icon: "blocks",
        role: Session.ROLE_QOGNI_ADMIN,
        menu: {
          side: {
            index: 10,
          },
        },
      },
      routes: {
        "/interest-topics": {
          name: "Interest Topic List",
          run: PageInterestTopicList,
          routes: {
            "/:id": {
              name: "Interest Topic Edit",
              run: PageInterestTopicEdit,
            },
          },
        },
        "/tags": {
          name: "Tag List",
          run: PageTagList,
          routes: {
            "/:id": {
              name: "Tag Edit",
              run: PageTagEdit,
            },
          },
        },
        "/glossary-definitions": {
          name: "Glossary Definition List",
          run: PageGlossaryDefinitionList,
          routes: {
            "/:id": {
              name: "Glossary Definition Edit",
              run: PageGlossaryDefinitionEdit,
              routes: {
                "/destroy": {
                  run: PageGlossaryDefinitionDestroy,
                },
              },
            },
          },
        },
      },
    },
  },
};
